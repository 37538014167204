.App {
  min-width: 1200px;
}

.App-header {
  /* width: 100%; */
  position: relative;
  display: flex;
}

.App-banner {
  width: 100%;
}

.App-header-title {
  z-index: 99;
  position: absolute;
  top: 17px;
  left: 0;
  padding: 0 20px;
  color: #fff;
  font-size: 20px;
  font-weight: bolder;
  display: flex;
  align-items: center;
}

.App-header-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-content-main {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.header-content-text1 {
  font-size: 32px;
  color: #ffffff;
  font-weight: bolder;
}

.header-content-text2 {
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
  margin-top: 14px;
}

.header-content-action {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 22px;
}

.radius {
  border-radius: 4px;
}

.header-content-action-box {
  position: relative;
  width: 558px;
  height: 42px;
}

.header-content-input {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: none;
  font-size: 14px;
  color: #000000;
  line-height: 14px;
  font-weight: 400;
  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 89px;
}

.header-content-input:focus {
  border: none;
  outline: none;
}

.input-action {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.input-split {
  width: 1px;
  height: 22px;
  background: #888888;
  margin-right: 16px;
}

.header-content-start {
  height: 42px;
  background: #006eff;
  border: none;
  margin-left: 10px;
  font-size: 16px;
  color: #ffffff;
  font-weight: bolder;
  box-sizing: border-box;
}

.header-content-stop {
  height: 42px;
  border: none;
  margin-left: 10px;
  font-size: 16px;
  color: #ffffff;
  font-weight: bolder;
  box-sizing: border-box;
}

.header-content-or {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  margin: 0 10px;
}

.header-content-check {
  height: 42px;
  border: none;
  font-size: 16px;
  color: #000000;
  font-weight: 500;
  font-weight: bolder;
}

.tea-dropdown__header > .tea-icon {
  top: 8px;
}

.tea-dropdown__value {
  text-align: end;
}

.App-main {
  display: flex;
  justify-content: center;
  padding: 30px 0;
}

.App-main-box {
  width: calc(100vw * 0.8);
  min-width: 1124px;
  max-width: 1360px;
}

.max-line-2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
